.login_body {
    display: flex;
    background: url('./images/loginbanner1.jpg');
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
.login_container {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    height: fit-content;
    min-width: 250px;
    width: fit-content;
    padding: 30px;
    padding-top: 0px;
    border-radius: 10px;
    margin-bottom: 100px;
    transform: scale(120%);

}

.form_input {
    margin-bottom: 13px;
    height: 20px;
    width: 180px;
    border-radius:5px;
    padding: 4px;
    padding-left: 6px;
    border: 1px solid rgb(19, 26, 128);
    font-size: 12px;
}

.loginbuttons_section {
    display: flex;
}

.loginpage_button {
    padding: 9px;
    background-color: rgb(15, 15, 78);
    color: white;
    border: 1px solid rgb(15, 15, 78);
    border-radius: 3px;
    transition: all 0.3s;
    margin-left: 10px;
    margin-right: 10px;
}

.loginpage_button:hover {
    cursor: pointer;
    color: rgb(15, 15, 78);
    border: 1px solid rgb(15, 15, 78);
    background-color: rgba(255, 255, 255, 0.675);
}

.login_title_form {
    font-size: 30px;
    color: rgb(15, 15, 78);

}