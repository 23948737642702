.first_section {
    background: linear-gradient(
        rgba(0, 2, 44, 0.168),  
        rgba(0, 0, 0, 0)       
    ),
    url('./images/mainpage1.jpeg');  
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    width: 100vw;
    height: 100vh;
    align-items: center;
}

.first_section_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 89vh;
}

.first_section_title {
    display: flex;
    color: white;
    margin: 0px;
    margin-top: -150px;
    margin-bottom: 20px;
    font-size: 100px;
    backdrop-filter: blur(0.8px);
    letter-spacing: 5px;
    text-shadow: 8px 8px 10px rgb(0, 0, 20);
    
}

.first_section_subtitle {
    color: white;
    min-height: 24px;
    font-size: 20px;
    backdrop-filter: blur(0.8px);
    letter-spacing: 2px;
    margin: 0px;
    margin-bottom: 35px;
    padding: 5px;
    text-shadow: 8px 8px 10px rgb(0, 0, 20);
    backdrop-filter: blur(2px);
}


.first_section_buttons {
    display: flex;
}

.firstsection_button {
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    padding: 8px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 3, 54);
    box-shadow: 2px 2px 50px rgb(40, 40, 40);
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 30px;
    transition: all 0.4s;
}

.fs_button_icon {
    transform: scale(80%);
}

.firstsection_button1 {
    margin-left: 40px;
    margin-right: 40px;
    padding: 8px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    background-color: rgba(0, 1, 45, 0.574);
    color: rgb(255, 255, 255);
    box-shadow: 2px 2px 50px rgb(40, 40, 40);
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 30px;
    transition: all 0.4s;

}


.firstsection_button:hover {
    background-color: rgba(0, 1, 45, 0.574);
    color: rgb(255, 255, 255);
    box-shadow: 2px 2px 50px rgb(40, 40, 40);
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
}

.firstsection_button1:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 3, 54);
    box-shadow: 2px 2px 50px rgb(40, 40, 40);
    border: 1px solid rgba(255, 255, 255, 0);
}