
  .finpal_body {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    margin: 0;
    background-color: #f4f4f9;
  }
  
  .finpal_left {
    flex: 0.2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    background: url('images/bot_bg1.jpeg');
    background-size: cover;
    background-position: 60%;
    position: relative;
  }
  
  .finpal_left::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      rgba(4, 3, 64, 0.61),
      rgba(0,0,0, 0)
    );
    backdrop-filter: blur(3px);
    z-index: 0;
  }
  
  .finpal_left > * {
    position: relative;
    z-index: 1;
  }
  
  .finpal_left_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    padding: 20px;
  }
  
  .finpal_left_header > img {
    width: 75px;
    height: auto;
    margin-top: 20px;
    transform: scale(120%);
  }
  
  .new_chatbutton {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .new_chat_button {
    margin: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #f4f4f9;
    color: #060658;
    font-size: 19px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .new_chat_button:hover {
    background-color: #f2ca00;
    color: #060658;
  }
  
  .new_chat_buttons {
    margin: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    background-color: #f4f4f9;
    color: #060658;
    font-size: 19px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .new_chat_buttons:hover {
    background-color: #060658;
    color: #f2ca00;
  }
  
  .past_chats {
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }
  
  .chat_history {
    display: flex;
    align-items: center;
    font-size: 17px;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin: 20px;
    margin-bottom: 14px;
    background-color: #f4f4f9;
    color: #060658;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transform: scale(93%);
    transition: all 0.3s;
  }
  
  .chat_history:hover {
    background-color: #060658;
    box-shadow: 1px 1px 20px #ffffff;
    color: #f2ca00;
    cursor: pointer;
  }
  
  .chat_history:hover .chat_bar_icon {
    color: #f2ca00;
  }
  
  .chat_history:hover .chat_name_input {
    color: #f2ca00;
  }
  
  .chat_name_input {
    width: 70%;
    border: none;
    background: none;
    color: #060658;
    text-align: center;
    font-size: 17px;
    min-height: 50px;
    font-weight: bold;
    transition: all 0.3s;
  }
  
  .chat_name_input:focus {
    outline: none;
  }
  
  .edit_button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .chat_actions {
    align-self: flex-end;
  }

  .chat_bar_icon {
    color: #f4f4f9;
  }
  
  .finpal_left_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
  }
  
  .finpal_right {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .finpal_right_header {
    display: flex;
    background-color: #060658;
  }
  
  .chat_background {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: url('images/bot_bg1.jpeg') center/cover no-repeat;
  }
  
  .bot_messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 30px;
    overflow-y: scroll;
    height: 87vh;
  }
  
  .bot_message_container, .user_message_container {
    display: flex;
    background-color: #f4f4f9;
    width: fit-content;
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 17px;
  }
  
  .bot_message_container {
    align-self: flex-start;
    max-width: 630px;
  }
  
  .user_message_container {
    align-self: flex-end;
    background-color: #ffffff;
    max-width: 630px;
  }
  
  .message {
    margin: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: justify;
    line-height: 22px;
  }
  
  .user_input_section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5vh;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
  }
  
  .user_input {
    padding: 13px;
    width: 35vw;
    height: 2.5vh;
    font-size: 18px;
    border: none;
    border-radius: 40px;
    outline: none;
  }
  
  #user_input_submit {
    border-radius: 30px;
    background-color: black;
    color: white;
    border: none;
    height: fit-content;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  #user_input_submit:hover {
    background-color: #ffffff;
    color: black;
  }
  
  .input_bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 0 20px;
    border-radius: 40px;
    width: fit-content;
    margin-bottom: 20px;
  }
  
  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-left: 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  