* {
    margin: 0px;
}

.financial_news {
    background: url('./images/loginbanner1.jpg');
    background-size: cover;
    background-attachment: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: white;
    overflow: hidden; /* Prevents scrolling */
}

.financial_news h2 {
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Added text shadow for better visibility */
}

#articles {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-template-rows: repeat(4, 1fr); /* 4 rows */
    gap: 20px;
    width: 100%;
    height: 100%; /* Ensure the grid takes full height */
    max-width: 1200px;
}

.article {
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background for readability */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Added box shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; /* Ensure no overflow */
}

.article h2 {
    margin-bottom: 10px;
    font-size: 1.2em;
    text-align: center;
    color: #f2ca00; /* Added color for heading */
    overflow: hidden;
    text-overflow: ellipsis; /* Ensures long titles are truncated */
}

.article img {
    width: 100%;
    height: auto;
    max-height: 100px; /* Ensures all images are the same height */
    object-fit: cover; /* Ensures images cover the area without distortion */
    border-radius: 10px;
    margin-bottom: 10px;
}

.article a {
    color: #f2ca00; /* Link color */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s; /* Smooth transition for link color */
}

.article a:hover {
    color: #ffffff; /* Link color on hover */
}
