.header_body {
    display: flex;
    width: 100vw;
    height: 8vh;
    padding-top: 10px;
    padding-bottom: 10px;
    background: linear-gradient(
        rgba(9, 0, 109, 0.881),
        rgba(0,0,0, 0)
    );
    backdrop-filter: blur(1.5px);
}


.header_left {
    flex: 0.25;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    padding: 3px;
}

.header_left img {
    transform: scale(80%);
}


.header_middle {
    flex: 0.5;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
    padding: 3px;
}

.header_right {
    flex: 0.25;
    font-size: 12px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.login_button {
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    border: 2px solid white;
    border-radius: 10px;
    transition: background-color 0.3s, color 0.1s;
    transform: scale(90%);
}

.login_button h3 {
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
}


.login_button:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

a {
    text-decoration: none;
}


