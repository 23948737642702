.homebutton_title {
    font-size: 15px;
    font-weight: 300;
    margin-left: 30px;
    margin-right: 30px;
    color: white;
    letter-spacing: 1px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
    transition: font-size 0.3s;

}

.homebutton_title:hover {
    font-size: 20px;
}

